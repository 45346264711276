.feed {
    max-width: 852px;
    margin: auto;
    padding: 0 10px;
}

.scroll-top {
    @include flex(0, center, center);
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    background-color: $main;
    color: white;
    border-radius: 50%;
    cursor: pointer;
    z-index: 100;

    &:hover {
        background-color: darken($main, 10%);
    }
}

.post {
    border: 2px solid rgba($bright-back, 0.7);
    box-shadow: 0px 2px 5px rgba($bright-back, 0.3);
    border-radius: 10px;
    margin-bottom: 40px;
    padding: 10px;

    p {
        margin: 0;
    }

    .time {
        font-size: 14px;
        font-weight: 100;
        opacity: 0.7;
    }

    .text {
        font-size: 16px;
        margin: 10px 0;
    }

    .img-main {
        width: 100%;
        object-fit: contain;
        max-height: 832px;
        border-radius: 20px;
        margin: 20px 0;
        cursor: pointer;
    }

    video {
        width: 100%;
        max-height: 832px;
        border-radius: 20px;
        margin: 20px 0;
    }

    .poll {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 20px 0;

        .opt-wrapper {
            width: 100%;
            @include flex(0, flex-start, center);
            border: 2px solid $bright-back;
            border-radius: 10px;

            .text-wrapper {
                @include flex(10px, flex-start, center);
                flex: 1;
                height: 125px;
                position: relative;

                .text {
                    flex: 1;
                    padding: 10px 0;
                }

                .bar {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    background-color: $bright-back;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                }

                p {
                    margin: 0 5px;
                    z-index: 1;
                }
            }

            img {
                width: 125px;
                height: 125px;
                object-fit: cover;
            }
        }
    }

    .profile-mini{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
    }

    .lower {
        @include flex(30px, flex-start, center);
        margin-bottom: 20px;

        .actionable {
            color: $main;
            span {
                cursor: pointer;
            }

            &:hover {
                background-color: $bright-back;
            }
        }

        div {
            @include flex(5px, flex-start, center);
            padding: 2px 6px;
            border-radius: 10px;
        }
    }

    .add-comment {
        @include flex(10px, flex-start, center);
        margin-bottom: 30px;

        input {
            flex: 1;
            height: 30px;
            border: none;
            background-color: $bright-back;
            padding-left: 10px;
            color: #ffff;
            border-radius: 10px;
            font-size: 16px;
        }

        button {
            background-color: $main;
            color: white;
            border: none;
            border-radius: 50%;
            height: 30px;
            width: 30px;
            @include flex(0, center, center);

            span {
                font-size: 20px;
            }
        }
    }
    .show-replies{
        @include flex(0px, flex-start, center);
        color: $sec;
        cursor: pointer;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 852px) {
    .post {
        .img-main,video{
            max-height: 600px;
        }
        .poll {
    
            .opt-wrapper {
    
                .text-wrapper {
                    height: 75px;
                }
    
                img {
                    width: 75px;
                    height: 75px;
                }
            }
        }
    }  
}