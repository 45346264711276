//basics
@import "/src/style/base/variables";
@import "/src/style/base/helpers";
@import "/src/style/base/font-face";
@import "/src/style/base/mixins";
@import "/src/style/base/themes";

//pages
@import "/src/style/pages/profile.scss";
@import "/src/style/pages/home.scss";
@import "/src/style/pages/stream.scss";
@import "/src/style/pages/stream-mobile.scss";
@import "/src/style/pages/error.scss";
@import "/src/style/pages/login.scss";
@import "/src/style/pages/control.scss";
@import "src/style/pages/create";
@import "/src/style/pages/register";

//components
@import "/src/style/cmps/show-card.scss";
@import "/src/style/cmps/control-waiting";
@import "/src/style/cmps/control-current";
@import "/src/style/cmps/control-history";
@import "/src/style/cmps/create-feed";
@import "/src/style/cmps/control-soltiz";
@import "/src/style/cmps/header";
@import "/src/style/cmps/comment";
@import "/src/style/cmps/analytics";
@import "/src/style/cmps/options-bar";
@import "src/style/cmps/menu";
@import "src/style/cmps/popup";
@import "src/style/cmps/settings-popup";
@import "src/style/cmps/feed";
@import "src/style/cmps/footer";
@import "src/style/cmps/chat-box";
@import "src/style/cmps/loader";
@import "src/style/cmps/stream-chat";
@import "src/style/cmps/upper-popup";
@import "src/style/cmps/simple-popup";
@import "src/style/cmps/dev";

* {
    box-sizing: border-box;
    font-family: roboto-normal Arial, sans-serif;
    scroll-behavior: smooth;
    position: relative;
}

body {
    margin: 0;
    position: relative;
    background-color: $back-dark;
}

.app {
    min-height: 100vh;
    @include flex(0, space-between, center, column)
}

.main-layout {
    margin: auto;
    margin-top: 60px;
    width: 100vw;
    flex: 1;
    display: flex;
    flex-direction: column;
}

@media (max-width: 930px) {
    .main-layout {
        margin-bottom: 60px;
    }
}

input{
    text-align: right;
    direction: rtl;
}

textarea {
    resize: none;
}

textarea:focus,
input:focus {
    outline: none;
    -webkit-appearance: none;
}

button {
    cursor: pointer;
    border: none;
}

h1 {
    font-weight: 400;
}