.chat-page {
    height: calc(100vh - 20px);
    display: flex;
    flex-direction: column;

    .image-wrapper {
        width: 100%;
        @include flex(0, flex-end, flex-start);
        margin: 10px;
        padding-right: 10px;
    }

    .attached-image {
        max-width: 500px;
        max-height: 500px;
        object-fit: contain;
        border-radius: 10px;
    }
    .messages-container {
        overflow-y: auto;
        flex:1 1 auto;
    }
}

.chat-box {
    display: flex;
    width: 340px;
    flex-direction: column;
    position: relative;
    overflow-y: scroll;
    border-left: 1px solid;
    box-shadow: 0px 0px 10px 0px #000000;

    .chat-header {
        flex: 4;
        @include flex(0, space-between, center);
        border-bottom: 1px solid $bright-back;
        padding: 0 10px;

        h1 {
            font-size: 20px;
            margin: 0;
            margin-left: 10px;
            color: #fff;
        }

        span {
            font-size: 25px;
            color: #fff;
            opacity: 0.7;
        }
    }

    .reconnect-chat {
        width: 100%;
        text-align: center;
        text-decoration: underline;
        color: #AA0000;
        cursor: pointer;
        padding-bottom: 20px;
    }
}

.pinned {
    background-color: $main;
    width: 100%;
    padding: 5px;
    border-radius: 10px;
    @include flex(0, center, center);

    img {
        width: 50px;
        aspect-ratio: 1/1;
        border-radius: 50%;
    }

    .remove {
        padding: 5px;
        background-color: #AA0000;
        border-radius: 50%;
        cursor: pointer;
    }
}

.body-text {
    position: relative;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    flex: 32;
    background-color: $back-dark;
}

.messages-container {
    overflow-y: scroll;
    height: calc(100vh - 210px)
}

.chat-box-input {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 5px;
    gap: 10px;

    input {
        flex: 1;
        height: 40px;
        border: none;
        background-color: $bright-back;
        padding-left: 20px;
        color: #ffff;
        border-radius: 10px;
        font-size: 16px;
    }

    input:focus {
        outline: 1px solid $main;
    }

    ::placeholder {
        color: rgba(255, 255, 255, 0.5);
        font-size: 16px;
    }

    .button {
        width: 40px;
        height: 40px;
        background-color: $main;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 20px;
        color: white;
    }
}

.message-text {
    width: 100%;
    padding: 5px;
    cursor: pointer;
    color: #ffffff;
    text-align: right;
    direction: rtl;
    margin: 0;

    span {
        margin: 0;
        margin-left: 0px;
        color: #ffffff;
        font-size: 16px;
        word-break: break-word; 
    }
}

.nickName {
    margin-right: 5px;
    font-size: 20px;
}

.message-body {
    color: rgba(255, 255, 255, 0.85);
    margin-left: 5px;
    font-size: 16px;
    margin-right: 5px;
}

.avatar {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px;
}

.ban-menu {
    position: absolute;
    width: 90%;
    left: 50%;
    background-color: black;
    border: 2px solid $main;
    border-radius: 10px;
    padding: 10px;
    color: white;
    transform: translateX(-50%);
    z-index: 1;

    p {
        max-width: 100%;
        word-wrap: break-word;
        overflow-wrap: break-word;
    }

    .name {
        color: $main;
        font-size: 20px;
        text-align: center;
        margin-bottom: 10px;
    }

    .selects {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    select {
        flex: 1;
        font-size: 16px;
        padding: 5px;
        background-color: $bright-back;
        color: white;
        border: 1px solid $main;
        border-radius: 5px;
    }

    select:focus {
        outline: 0px solid $main;
    }

    .buttons {
        @include flex(10px, center, center, column);
        margin-top: 20px;
        gap: 10px;

        &>div:first-child {
            background-color: $main;
        }

        &>div:nth-child(2) {
            background-color: $bright-back;
        }

        div {
            width: 100%;
            height: 30px;
            border-radius: 10px;
            @include flex(0, center, center);
            cursor: pointer;
            border: 1px solid $main;
            height: 35px;
            width: 100%;
        }
    }
}

@media only screen and (max-width: 1750px) {
    .chat-box {
        display: flex;
        flex: 6;
    }
}

@media only screen and (max-width: 1300px) {
    .chat-box {
        display: flex;
        flex: 10;
    }
}

@media (max-width: 1100px) {
    .chat-page {
        height: calc(100vh - 120px);
        min-height: unset !important;

        .image-wrapper {
            width: 100%;
            @include flex(0, flex-end, flex-start);
            margin: 10px 0;
            padding-right: 0px;
        }

        .attached-image {
            max-width: 100%;
            max-height: 400px;
            object-fit: contain;
        }

        .pinned {
            margin: 5px 0;

            img {
                width: 30px;
            }

            .remove {
                font-size: 14px;
            }
        }

        .messages-container {
            overflow-y: auto;
            flex: 1 1 auto;
        }
    }

    .chat-box {
        display: flex;
        flex: 1;
        height: 100%;
        min-height: 95lvh;
        width: 270px;

        .chat-header {
            @include flex(0, center, center);
        }
    }

    .messages-container {
        overflow-y: scroll;
        height: calc(100vh - 230px)
    }

    .chat-box-input {
        input {
            height: 35px;
        }

        .button {
            width: 30px;
            height: 30px;
            font-size: 14px;
        }
    }

    .chat-bar-mobile {
        height: 45px;
        color: white;
        background-color: $back-dark;
        @include flex(0, space-between, center);
        font-size: 16px;
        padding: 0 15px;

        span {
            font-size: 25px;
            opacity: 0.7;
        }
    }

    .ban-menu {
        .name {
            font-size: 16px;
            margin-bottom: 6px;
        }

        p{
            max-width: 100%;
        }

        .selects {
            gap: 6px;
        }

        select {
            font-size: 14px;
            padding: 3px;
            background-color: $bright-back;
        }

        .buttons {
            margin-top: 10px;
            gap: 10px;

            div {
                width: 100%;
                height: 25px;
                border-radius: 10px;
                font-size: 16px;
            }
        }
    }
}