.profile {
    padding-left: 0;
    padding-top: 30px;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid white;
    width: 100%;

    .banner-wrapper {
        width: 100%;
        aspect-ratio: 8/1;
        @include flex(0, center, center);
        position: relative;
        margin-bottom: 30px;

        label {
            width: 100%;
        }

       img{
            width: 100%;
           aspect-ratio: 6.5/1;
            object-fit: cover;
            cursor: pointer;
            border-radius: 10px;
        }

        .edit-icon {
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: $main;
            border-radius: 50%;
            padding: 5px;
            cursor: pointer;
        }
    }

    .profile-lower{
        width: 100%;
        @include flex(15px, flex-start, center);
    }

    .profile-details{
        @include flex(15px, flex-start, flex-start, column);
        height: 170px;
    }

    .img-wrapper {
        width: 170px;
        height: 170px;
        position: relative;

        @include flex(0, center, center);

        label {
            width: 100%;
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
            cursor: pointer;
        }

        .edit-icon {
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: $main;
            border-radius: 50%;
            padding: 5px;
            cursor: pointer;
        }
    }

    .name-wrapper {
        @include flex(5px, flex-start, center);

        .edit-name {
            @include flex(20px, flex-start, center);
        }

        .edit-button {
            all: unset;
            background-color: $back-dark;
            cursor: pointer;
            height: 30px;
            width: 30px;
            border-radius: 8px;
            @include flex(0, center, center);
            border-radius: 50%;

            span {
                color: white;
                font-size: 22px;
            }
        }

        .name {
            all: unset;
            width: 300px;
            color: white;
            background-color: transparent;
            font-size: 32px;
            margin: 0;
            padding-left: 5px;
        }
        .desc{
            font-size: 14px;
            opacity: 0.7;
        }
        .border {
            outline: 2px solid $main;
            border-radius: 15px;
        }
        textarea {
            font-size: 14px !important;
            height: 120px !important;
            
        }
    }
}



@media (max-width: $mobile) {
    .profile {
        @include flex(10px, flex-start, center, column);
        padding-left: 0;
        padding: 10px;

        .banner-wrapper{
            margin-bottom: 10px;
        }

        .img-wrapper {
                width: 75px;
                height: 75px;
        }

        .profile-lower{
            @include flex(10px, flex-start, flex-start);
        }

        .profile-details{
            gap: 0px;
            height: fit-content;
        }

        form {
            gap: 10px;

            input {
                height: 25px;
                font-size: 20px;
            }

            button {
                cursor: pointer;
                height: 25px;
                width: 25px;

                span {
                    font-size: 20px;
                }
            }
        }

        .name-wrapper {
            width: 100%;
            gap: 0;

            .edit-button {
                height: 35px;
                width: 35px;

                span {
                    font-size: 18px;
                }
            }

            .edit-name {
                @include flex(8px, flex-start, center);
            }

            .name {
                font-size: 16px;
                margin: 0;
                padding-left: 10px;
                width: 200px !important;
                word-break: break-all;
            }

            .desc{
                font-size: 14px;
            }
        }
    }
}